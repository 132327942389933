body {
  margin: 0;
  font-family: -apple-system, BlinkMacSystemFont, "Segoe UI", "Roboto", "Oxygen",
    "Ubuntu", "Cantarell", "Fira Sans", "Droid Sans", "Helvetica Neue",
    sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  background-color: #282c34;
  box-sizing: border-box;
  line-height: 1.4;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, "Courier New",
    monospace;
}

h1 {
  font-size: 2rem;
}

a {
  color: red;
  text-decoration: none;
}
a:hover {
  color: white;
}
textarea:focus {
  outline: none;
}

textarea {
  padding: 0.5rem;
  line-height: inherit;
}

label {
  display: flex;
  justify-content: space-between;
  width: 100%;
}

.label-text {
  width: 30%;
}

input {
  width: 65%;
}
